import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
	Platform,
	RefreshControl,
	ScrollView,
	StyleSheet,
	View,
} from "react-native";

import LoadingState from "../components/LoadingState";
import NewsCard from "../components/NewsCard";
import { useNews } from "../components/NewsContext";

export default function NewsScreen() {
	const navigation = useNavigation();
	const { loading, error, result, reload } = useNews();
	const empty = result && !result.length;

	return (
		<ScrollView
			contentInsetAdjustmentBehavior="automatic"
			contentContainerStyle={styles.container}
			style={styles.scrollView}
			refreshControl={
				<RefreshControl refreshing={loading} onRefresh={reload} />
			}
		>
			<LoadingState error={error} empty={empty} onReload={reload}>
				<View style={styles.cards}>
					{result &&
						result.map((news, i) => (
							<NewsCard
								key={i}
								news={news}
								style={styles.card}
								onPress={() => navigation.navigate("News.Item", { news })}
							/>
						))}
				</View>
			</LoadingState>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		flex: 1,
	},

	container: Platform.select({
		web: {
			paddingVertical: 15,
		},
		default: {
			padding: 15,
		},
	}),

	cards: Platform.select({
		web: {
			marginBottom: -10,
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center",
		},
		default: {
			marginTop: -20,
		},
	}),

	card: Platform.select({
		web: {
			width: 400,
			margin: 10,
		},
		default: {
			marginTop: 20,
		},
	}),
});
