import React from "react";

export default function useRefreshControlVisibleWhileLoading(loading) {
	const [visible, setVisible] = React.useState(loading);

	React.useEffect(() => {
		if (loading && !visible) {
			setVisible(true);
		} else if (!loading && visible) {
			setTimeout(() => setVisible(false), 1000);
		}
	}, [loading, visible, setVisible]);

	return loading || visible;
}
