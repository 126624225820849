import { FontAwesome5 } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React from "react";
import { Platform } from "react-native";

import MoreNavigator from "./MoreNavigator";
import NewsNavigator from "./NewsNavigator";
import { DarkHeader } from "./Options";
import { useNews } from "../components/NewsContext";
import TabBadge from "../components/TabBadge";
import WebHeaderTabBar from "../components/WebHeaderTabBar";
import FavoriteMedicinesScreen from "../screens/FavoriteMedicinesScreen";
import MedicinesScreen from "../screens/MedicinesScreen";

const Tab =
	Platform.OS === "web"
		? createMaterialTopTabNavigator()
		: createBottomTabNavigator();

const tabBarIcon =
	(name) =>
	({ color, size }) => (
		<FontAwesome5
			name={name}
			color={color}
			size={Platform.OS === "web" ? 24 : size}
		/>
	);

export default function TabNavigator() {
	const news = useNews();
	const unreadNewsCount = news.result.filter((item) => !item.read).length;

	return (
		<Tab.Navigator
			screenOptions={{
				...DarkHeader,
				...(Platform.OS === "web"
					? {
							tabBarStyle: { backgroundColor: "#003399" },
							tabBarActiveTintColor: "#FFFFFF",
							tabBarLabelStyle: { fontSize: 18, textTransform: "none" },
							tabBarIndicatorStyle: { backgroundColor: "#003399" },
							swipeEnabled: false,
							animationEnabled: false,
					  }
					: {
							tabBarActiveTintColor: "#003399",
					  }),
			}}
			sceneContainerStyle={{ backgroundColor: "#FFFFFF" }}
			tabBar={
				Platform.OS === "web"
					? (props) => <WebHeaderTabBar {...props} />
					: undefined
			}
		>
			<Tab.Screen
				name="Medicines"
				component={MedicinesScreen}
				options={{
					title: "Glaucoommedicatie",
					tabBarLabel: "Medicatie",
					tabBarIcon: tabBarIcon("eye-dropper"),
				}}
			/>
			<Tab.Screen
				name="Favorites"
				component={FavoriteMedicinesScreen}
				options={{
					title: "Favorieten",
					tabBarLabel: "Favorieten",
					tabBarIcon: tabBarIcon("star"),
				}}
			/>
			<Tab.Screen
				name="News"
				component={NewsNavigator}
				options={{
					tabBarBadge: unreadNewsCount
						? Platform.OS === "web"
							? () => <TabBadge tabCount={4}>{unreadNewsCount}</TabBadge>
							: unreadNewsCount
						: undefined,
					headerShown: false,
					tabBarLabel: "Nieuws",
					tabBarIcon: tabBarIcon("newspaper"),
				}}
			/>
			<Tab.Screen
				name="More"
				component={MoreNavigator}
				options={{
					headerShown: false,
					tabBarLabel: "Meer",
					tabBarIcon: tabBarIcon("ellipsis-h"),
				}}
			/>
		</Tab.Navigator>
	);
}
