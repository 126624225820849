import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import {
	HeaderButtons as RNHeaderButtons,
	HeaderButton as RNHeaderButton,
	Item as RNItem,
} from "react-navigation-header-buttons";

const iconColor = "#FFFFFF";

function HeaderButton(props) {
	return (
		<RNHeaderButton
			{...props}
			buttonStyle={[props.buttonStyle, props.disabled && { color: "#BBBBBB" }]}
			IconComponent={FontAwesome5}
			iconSize={23}
			color={iconColor}
		/>
	);
}

export default function HeaderButtons(props) {
	return (
		<RNHeaderButtons
			HeaderButtonComponent={HeaderButton}
			OverflowIcon={
				<FontAwesome5 name="ellipsis-v" size={23} color={iconColor} />
			}
			{...props}
		/>
	);
}

export const Item = RNItem;
