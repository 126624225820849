import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import SecondarySetupButton from "../components/SecondarySetupButton";
import SetupButton from "../components/SetupButton";

export default function SplashScreen(props) {
	const next = React.useCallback(
		() => props.navigation.navigate("Setup_Login"),
		[props.navigation],
	);

	const showDisclaimer = React.useCallback(
		() => props.navigation.navigate("Setup_Disclaimer"),
		[props.navigation],
	);

	return (
		<View style={styles.container}>
			<Image
				source={require("../assets/images/splash.png")}
				resizeMethod="scale"
				style={styles.splash}
			/>
			<SafeAreaView style={styles.buttonContainer}>
				<SetupButton title="Volgende" onPress={next} />
				<SecondarySetupButton
					title="Disclaimer & privacy"
					onPress={showDisclaimer}
				/>
			</SafeAreaView>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},

	splash: {
		flex: 1,
		resizeMode: "contain",
		width: null,
		height: undefined,
	},

	buttonContainer: {
		position: "absolute",
		left: 20,
		right: 20,
		bottom: 10,
	},
});
