import React from "react";
import { useSelector } from "react-redux";

import { useFavoritedMedicines } from "../components/MedicationContext";
import MedicinesList from "../components/MedicinesList";
import { favorites } from "../store";

export default function FavoriteMedicinesScreen() {
	const favoriteIds = useSelector(favorites.selectors.getState);
	const medicines = useFavoritedMedicines(favoriteIds);

	return (
		<MedicinesList
			medicines={medicines}
			emptyText="Bewaar favorieten met de ☆-knop."
		/>
	);
}
