import React from "react";
import {
	ScrollView,
	StyleSheet,
	Text,
	View,
	Platform,
	Linking,
	RefreshControl,
} from "react-native";

import LoadingState from "./LoadingState";
import { useMedication } from "./MedicationContext";
import MedicineCard from "../components/MedicineCard";
import Disclaimer from "../constants/Disclaimer";
import useRefreshControlVisibleWhileLoading from "../hooks/useRefreshControlVisibleWhileLoading";

export default function MedicinesList(props) {
	// TODO: [iOS] Scroll view top inset too large in medicines screen

	const { loading, error, reload } = useMedication();
	const refreshControlVisible = useRefreshControlVisibleWhileLoading(loading);

	const sources = Disclaimer.find((section) => !!section.sources);

	return (
		<ScrollView
			ref={props.scrollViewRef}
			alwaysBounceVertical={!!props.medicines.length}
			contentInsetAdjustmentBehavior="automatic"
			style={styles.scrollView}
			contentContainerStyle={styles.container}
			refreshControl={
				refreshControlVisible && (
					<RefreshControl refreshing={loading} enabled={false} />
				)
			}
		>
			<LoadingState
				loading={loading}
				error={error}
				onReload={reload}
				hideWhileLoading
			>
				{props.medicines.length ? (
					<>
						<View style={styles.cards}>
							{props.medicines.map((medicine, i) => (
								<MedicineCard
									key={medicine.uuid}
									medicine={medicine}
									style={styles.card}
								/>
							))}
						</View>
						<Text style={styles.sourcesText}>
							<Text style={{ fontWeight: "bold" }}>{`${sources.title}: `}</Text>
							{Object.entries(sources.sources).map(([source, url], i) => (
								<React.Fragment key={i}>
									{i > 0 && ", "}
									<Text
										style={styles.sourcesLink}
										onPress={() => Linking.openURL(url)}
									>
										{source}
									</Text>
								</React.Fragment>
							))}
						</Text>
					</>
				) : (
					<Text style={styles.noResultsText}>
						{props.emptyText || "Geen resultaten gevonden."}
					</Text>
				)}
			</LoadingState>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		height: 0 /* required to make scrolling work on web */,
		flex: 1,
		//backgroundColor: "red",
	},

	container: Platform.select({
		web: {
			paddingVertical: 15,
			flex: 1,
		},
		default: {
			padding: 15,
		},
	}),

	cards: Platform.select({
		web: {
			marginBottom: -10,
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center",
		},
		default: {
			marginTop: -20,
		},
	}),

	card: Platform.select({
		web: {
			width: 400,
			margin: 10,
		},
		default: {
			marginTop: 20,
		},
	}),

	sourcesText: {
		marginTop: 20,
		fontSize: 10,
		textAlign: "center",
		color: "#8E8E92",
	},

	sourcesLink: {
		textDecorationLine: "underline",
	},

	noResultsText: {
		marginTop: 100,
		fontFamily: "Raleway_500Medium",
		fontSize: 18,
		textAlign: "center",
		color: "#8E8E92",
	},
});
