import React from "react";
import { Platform, View } from "react-native";

export default function TouchableWrapper(props) {
	if (Platform.OS === "android") {
		return (
			<View
				{...props}
				style={[
					props.style,
					{ borderRadius: props.borderRadius, overflow: "hidden" },
				]}
			/>
		);
	}

	return props.children;
}
