export default [
	{
		title: "Disclaimer",
		phrases: [
			"Buzzera (Kamer van Koophandel: 01147188) verleent u hierbij toegang tot de Glaucoommedicatie-app (“de App”).",
			"Buzzera behoudt zich daarbij het recht voor op elk moment de inhoud aan te passen, of onderdelen of de gehele App te verwijderen, zonder daarover aan u mededeling te hoeven doen.",
			"De App biedt informatie over glaucoommedicatie beschikbaar in Nederland en is uitsluitend bedoeld voor zorgprofessionals. De App is informatief bedoeld en is geen medisch hulpmiddel.",
			"De App is ontwikkeld door Buzzera in opdracht van Santen.",
		],
		logos: [
			{
				source: require("../assets/images/logo-santen.png"),
				label: "Santen",
			},
		],
	},
	{
		title: "Beperkte aansprakelijkheid",
		phrases: [
			"Santen spant zich in om de inhoud van de App zo vaak mogelijk te actualiseren en/of aan te vullen. Ondanks deze zorg en aandacht is het mogelijk dat inhoud onvolledig en/of onjuist is.",
			"De in de App aangeboden materialen worden aangeboden zonder enige vorm van garantie of aanspraak op volledigheid of juistheid. Deze materialen kunnen op elk moment aangepast of verwijderd worden zonder voorafgaande mededeling van Buzzera of Santen.",
		],
	},
	{
		title: "Auteursrechten",
		phrases: [
			"Alle rechten van intellectuele eigendom betreffende deze materialen liggen bij Buzzera en haar licentiegevers.",
			"Kopiëren, verspreiden en elk ander gebruik van deze materialen is niet toegestaan zonder schriftelijke toestemming van Buzzera, behoudens en slechts voor zover anders bepaald in regelingen van dwingend recht (zoals citaatrecht), tenzij bij specifieke materialen anders aangegeven is.",
		],
	},
	{
		title: "Bronnen",
		sources: {
			CBG: "https://www.cbg-meb.nl",
			SmPC: "https://www.geneesmiddeleninformatiebank.nl",
			"Farmacotherapeutisch kompas":
				"https://www.farmacotherapeutischkompas.nl",
		},
	},
	{
		title: "Privacy",
		phrases: [
			"In de App worden geen persoonsgegevens gevraagd of bewaard.",
			"Om mogelijke problemen met de App te herkennen en op te lossen, gebruiken we een dienst die rapporten verzamelt over fouten die optreden in de App. Deze rapporten bevatten technische informatie. Er worden geen IP-adressen opgeslagen, en we streven ernaar om zo min mogelijk persoonlijke gegevens te verzamelen. Als er persoonlijke gegevens worden verzameld, worden deze slechts zo lang bewaard als nodig is om het probleem op te lossen, en worden ze daarna verwijderd.",
		],
	},
	{
		title: "Overig",
		phrases: [
			"Deze disclaimer en privacyverklaring kunnen van tijd tot tijd wijzigen.",
		],
	},
];
