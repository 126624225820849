import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import FilterModeButton from "./FilterModeButton";
import { filtering } from "../store";

export default function FilterModeButtons({ showSheet, sheetVisible }) {
	const state = useSelector(filtering.selectors.getState);
	const filterCount = Object.entries(state.filters || {}).length;

	const dispatch = useDispatch();

	const setMode = React.useCallback(
		(mode) => dispatch(filtering.actions.setMode(mode)),
		[dispatch],
	);

	const toggleFilter = React.useCallback(
		(property, value) =>
			dispatch(filtering.actions.toggleFilter({ property, value })),
		[dispatch],
	);

	const reset = React.useCallback(() => {
		setMode("filters");
	}, [setMode]);

	const filter = React.useCallback(() => {
		setMode("filters");
		showSheet();
	}, [setMode, showSheet]);

	const search = React.useCallback(() => {
		setMode("search");
		showSheet();
	}, [setMode, showSheet]);

	const brand = React.useCallback(() => {
		setMode("brand");
		toggleFilter("brand", "Santen");
	}, [setMode, toggleFilter]);

	return (
		<>
			<View>
				<FilterModeButton
					title="Filteren"
					icon="sliders-h"
					selected={
						state.mode === "filters" && (sheetVisible || filterCount > 0)
					}
					onPress={state.mode === "filters" ? showSheet : filter}
				/>
			</View>
			<View style={styles.gap}>
				<FilterModeButton
					title="Zoeken"
					icon="search"
					selected={
						state.mode === "search" && (sheetVisible || state.searchQuery)
					}
					onPress={state.mode === "search" ? showSheet : search}
				/>
			</View>
			<View style={styles.gap}>
				<FilterModeButton
					title="Santen"
					selected={state.mode === "brand"}
					onPress={
						state.mode === "brand" && Platform.OS !== "web" ? reset : brand
					}
				/>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	gap: {
		marginLeft: 10,
	},
});
