import React from "react";
import { AppState } from "react-native";

import useDatabaseQuery from "../hooks/useDatabaseQuery";

export const SmpcContext = React.createContext();

export function SmpcProvider(props) {
	const value = useDatabaseQuery({
		query: "page('smpc').children.listed",
		select: {
			uuid: true,
			title: true,
			url: "page.smpcFile.toFile.url",
		},
	});

	React.useEffect(() => {
		const subscription = AppState.addEventListener(
			"change",
			(appState) => appState === "active" && value.reload(),
		);

		return () => subscription.remove();
	}, [value.reload]);

	return (
		<SmpcContext.Provider value={value}>{props.children}</SmpcContext.Provider>
	);
}

export function useSmpc() {
	return React.useContext(SmpcContext);
}
