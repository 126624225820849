import React from "react";

export default function useDatabaseQuery(query) {
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [result, setResult] = React.useState(undefined);

	React.useEffect(() => {
		loading &&
			fetch("https://database.glaucoommedicatie.nl/api/query", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(query),
			})
				.then((response) => response.json())
				.then((json) => setResult(json.result))
				.catch((error) => {
					console.error(error);
					setError(error.message || true);
					setResult(undefined);
				})
				.finally(() => setLoading(false));
	}, [loading]);

	const reload = React.useCallback(() => {
		setLoading(true);
		setError(false);
	}, [setLoading, setError]);

	return { loading, error, result, reload };
}
