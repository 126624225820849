import AsyncStorage from "@react-native-async-storage/async-storage";
import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import { isFavorited } from "./components/MedicationContext";

const favorites = createSlice({
	name: "favorites",
	initialState: [],

	reducers: {
		toggle: (state, action) => {
			const { uuid, oldIds } = action.payload;
			if (isFavorited({ uuid, oldIds }, state)) {
				[uuid, ...oldIds].map(
					(id) => state.includes(id) && state.splice(state.indexOf(id), 1),
				);
			} else {
				state.push(uuid);
			}
		},
	},
});

favorites.selectors = {};
favorites.selectors.getState = (state) => state.main.favorites;

const news = createSlice({
	name: "news",
	initialState: { read: [] },

	reducers: {
		markRead: (state, action) => {
			state.read.push(action.payload.uuid);
		},
	},
});

news.selectors = {};
news.selectors.getState = (state) => state.main.news;

const session = createSlice({
	name: "session",
	initialState: { loginCode: null, setupVersionCompleted: null },

	reducers: {
		setLoginCode: (state, action) => {
			state.loginCode = action.payload.loginCode;
		},

		setSetupVersionCompleted: (state, action) => {
			state.setupVersionCompleted = action.payload.setupVersionCompleted;
		},
	},
});

session.selectors = {};
session.selectors.getState = (state) => state.main.session;

const filtering = createSlice({
	name: "filtering",

	initialState: {
		mode: "filters",
		filters: {},
		searchQuery: "",
		sheetHeaderHeight: 0,
	},

	reducers: {
		setMode: (state, action) => {
			state.mode = action.payload;
			state.filters = {};
			state.searchQuery = "";
		},

		toggleFilter: (state, action) => {
			const { property, value } = action.payload;
			if (state.filters[property] && state.filters[property].includes(value)) {
				state.filters[property] = state.filters[property].filter(
					(v) => v !== value,
				);
				state.filters[property].length === 0 && delete state.filters[property];
			} else {
				state.filters[property] = [...(state.filters[property] || []), value];
			}
		},

		setSearchQuery: (state, action) => {
			state.searchQuery = action.payload;
		},

		setSheetHeaderHeight: (state, action) => {
			state.sheetHeaderHeight = action.payload;
		},
	},
});

filtering.selectors = {};
filtering.selectors.getState = (state) => state.temporary.filtering;

filtering.selectors.isFiltering = (state) =>
	(state.temporary.filtering.mode === "filters" &&
		Object.entries(state.temporary.filtering.filters).length) ||
	(state.temporary.filtering.mode === "search" &&
		state.temporary.filtering.searchQuery.length) ||
	state.temporary.filtering.mode === "brand";

filtering.selectors.getSheetHeaderHeight = (state) =>
	state.temporary.filtering.sheetHeaderHeight;

const store = configureStore({
	reducer: {
		main: persistReducer(
			{ key: "main", storage: AsyncStorage },
			combineReducers({
				favorites: favorites.reducer,
				news: news.reducer,
				session: session.reducer,
			}),
		),

		temporary: combineReducers({
			filtering: filtering.reducer,
		}),
	},

	middleware: [thunk],
	devTools: __DEV__,
});

const persistor = persistStore(store);

export default store;
export { persistor, favorites, news, session, filtering };
