import React from "react";
import {
	Image,
	Linking,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from "react-native";
import Markdown from "react-native-markdown-display";
import { useDispatch, useSelector } from "react-redux";

import SetupButton from "../components/SetupButton";
import { news } from "../store";

export default function NewsItemScreen(props) {
	const { news: item } = props.route.params;

	const dispatch = useDispatch();
	const newsRead = useSelector(news.selectors.getState).read;
	const isRead = newsRead.includes(item.uuid);

	React.useEffect(() => {
		!isRead && dispatch(news.actions.markRead({ uuid: item.uuid }));
	}, []);

	return (
		<ScrollView
			contentInsetAdjustmentBehavior="automatic"
			style={styles.scrollView}
			contentContainerStyle={styles.content}
		>
			<View style={styles.section}>
				{item.showImage && (
					<Image
						source={{ uri: item.imageFile.url }}
						style={[
							styles.image,
							{
								maxWidth: item.imageFile.width,
								aspectRatio: item.imageFile.width / item.imageFile.height,
							},
						]}
					/>
				)}
				<Text style={styles.date}>{item.date}</Text>
				<Text style={styles.title}>{item.title}</Text>
				<Markdown
					mergeStyle={false}
					style={{
						body: {
							fontSize: 20,
							fontFamily: "Raleway_500Medium",
						},
						italic: {
							fontFamily: "Raleway_500Medium_Italic",
						},
						strong: {
							fontFamily: "Raleway_700Bold",
						},
						link: {
							color: "#003399",
							textDecorationLine: "underline",
						},
					}}
				>
					{item.text}
				</Markdown>
				{item.showAttachment && (
					<SetupButton
						title={item.attachmentTitle}
						style={styles.button}
						onPress={() => Linking.openURL(item.attachmentFile.url)}
					/>
				)}
				{item.showLink && (
					<SetupButton
						title={item.linkTitle}
						style={styles.button}
						onPress={() => Linking.openURL(item.linkUrl)}
					/>
				)}
			</View>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		flex: 1,
	},

	content: {
		width: "100%",
		maxWidth: 800,
		alignSelf: "center",
	},

	section: {
		padding: 20,
	},

	image: {
		width: "100%",
		marginBottom: 20,
	},

	date: {
		fontFamily: "Raleway_600SemiBold",
		fontSize: 15,
		color: "#8E8E92",
		textTransform: "uppercase",
		marginBottom: 5,
	},

	title: {
		fontFamily: "Raleway_700Bold",
		fontSize: 25,
	},

	button: {
		marginTop: 10,
	},
});
