import { MaterialTopTabBar } from "@react-navigation/material-top-tabs";
import { Image, StyleSheet, View } from "react-native";

export default function WebHeaderTabBar(props) {
	return (
		<View style={styles.header}>
			<View style={styles.logo}>
				<Image
					style={styles.logoImage}
					source={require("../assets/images/logo-santen.png")}
				/>
			</View>
			<View style={styles.arrow} />
			<View style={styles.tabBar}>
				<MaterialTopTabBar {...props} />
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	header: {
		flexDirection: "row",
		borderBottomWidth: 1,
		borderBottomColor: "rgb(216, 216, 216)",
	},

	logo: {
		width: 300,
		alignItems: "center",
		justifyContent: "center",
	},

	logoImage: {
		width: 120,
		aspectRatio: 446 / 180,
	},

	arrow: {
		marginTop: 20,
		width: 0,
		height: 0,
		borderLeftWidth: (20 / 48) * 77.5,
		borderLeftColor: "transparent",
		borderBottomWidth: 77.5,
		borderBottomColor: "#003399",
	},

	tabBar: {
		flex: 1,
		marginTop: 20,
	},
});
