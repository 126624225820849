import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";

import FavoriteButton from "./FavoriteButton";
import { useMedication } from "./MedicationContext";

export default function MedicineCard(props) {
	const { medicine } = props;
	const isSanten = medicine.brand === "Santen";
	const properties = useMedication().result.properties;

	const uppercaseClass = medicine.class
		.toUpperCase()
		.replace("α".toUpperCase(), "α")
		.replace("β".toUpperCase(), "β");

	return (
		<View style={[styles.card, isSanten && styles.santenCard, props.style]}>
			<FavoriteButton medicine={medicine} style={styles.favoriteButton} />
			<Text style={[styles.classText, isSanten && styles.santenClassText]}>
				{uppercaseClass}
			</Text>
			<Text style={[styles.nameText, isSanten && styles.santenNameText]}>
				{medicine.brand && (
					<Text style={styles.brandText}>{medicine.brand + " "}</Text>
				)}
				{medicine.name}
			</Text>
			{properties.map(
				(property) =>
					property.visible &&
					medicine[property.key] && (
						<Property
							key={property.key}
							label={property.label}
							value={medicine[property.key]}
						/>
					),
			)}
		</View>
	);
}

function Property(props) {
	return (
		<View style={styles.property}>
			<Text style={styles.propertyLabelText}>{props.label}</Text>
			<Text style={styles.propertyValueText}>{props.value}</Text>
		</View>
	);
}

const styles = StyleSheet.create({
	card: {
		paddingVertical: 7,
		paddingHorizontal: 10,
		borderRadius: 10,
		borderWidth: StyleSheet.hairlineWidth,
		borderColor: "rgba(0, 0, 0, 0.08)",
		backgroundColor: "#FFFFFF",
		...Platform.select({
			android: {
				elevation: 4,
			},
			default: {
				shadowColor: "#000000",
				shadowOffset: { width: 0, height: 3 },
				shadowOpacity: 0.1,
				shadowRadius: 6,
			},
		}),
	},

	santenCard: {
		backgroundColor: "#F5FBFF",
	},

	favoriteButton: {
		position: "absolute",
		zIndex: 10,
		right: 5,
		top: 5,
	},

	classText: {
		marginRight: 20,
		fontFamily: "Raleway_600SemiBold",
		fontSize: 15,
		color: "#8E8E92",
	},

	santenClassText: {
		color: "#0099CC",
	},

	nameText: {
		marginTop: 3,
		fontFamily: "Raleway_700Bold",
		fontSize: 22,
	},

	santenNameText: {
		color: "#003399",
	},

	brandText: {
		fontFamily: "Raleway_500Medium",
	},

	property: {
		flexDirection: "row",
		marginTop: 3,
		paddingTop: 3,
		borderTopWidth: StyleSheet.hairlineWidth,
		borderTopColor: "rgba(0, 0, 0, 0.08)",
	},

	propertyLabelText: {
		fontFamily: "Raleway_500Medium",
		fontSize: 15,
		color: "#8E8E92",
		marginRight: 10,
	},

	propertyValueText: {
		flex: 1,
		fontFamily: "Raleway_500Medium",
		fontSize: 15,
		textAlign: "right",
	},
});
