import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import { DarkHeader, LightHeader } from "./Options";
import FilterScreen from "../screens/FilterScreen";
import FiltersScreen from "../screens/FiltersScreen";
import { filtering } from "../store";

const Stack = createStackNavigator();

export default function FilterNavigator() {
	const state = useSelector(filtering.selectors.getState);
	const filterCount = Object.entries(state.filters || {}).length;

	return (
		<Stack.Navigator
			screenOptions={{
				...(Platform.OS === "web" ? LightHeader : DarkHeader),
				cardStyle: {
					flex: 1,
					backgroundColor: Platform.OS === "web" ? "#fff" : "#003399",
				},
			}}
		>
			<Stack.Screen
				name="Filters"
				component={FiltersScreen}
				options={{
					...(state.mode === "filters"
						? {
								title: "Filters" + (filterCount > 0 ? ` (${filterCount})` : ""),
						  }
						: {
								headerShown: false,
						  }),
				}}
			/>
			<Stack.Screen
				name="Filter"
				component={FilterScreen}
				options={({ route }) => ({
					title: route.params.filter.label,
					headerBackTitle: "Filters",
				})}
			/>
		</Stack.Navigator>
	);
}
