import React from "react";
import { StyleSheet, Text, View } from "react-native";

import SecondarySetupButton from "./SecondarySetupButton";

export default function LoadingState(props) {
	if (props.error) {
		return (
			<View>
				<Text style={styles.stateText}>
					{typeof props.error === "string"
						? `Fout bij laden gegevens:\n${props.error}`
						: "Fout bij laden gegevens."}
				</Text>
				<SecondarySetupButton
					title="Opnieuw proberen"
					style={styles.button}
					onPress={props.onReload}
				/>
			</View>
		);
	}

	if (props.empty) {
		return <Text style={styles.stateText}>Geen resultaten gevonden.</Text>;
	}

	if (props.loading && props.hideWhileLoading) {
		return;
	}

	return props.children;
}

const styles = StyleSheet.create({
	stateText: {
		marginTop: 100,
		fontFamily: "Raleway_500Medium",
		fontSize: 18,
		textAlign: "center",
		color: "#8E8E92",
	},
	button: {
		marginTop: 20,
		alignSelf: "center",
	},
});
