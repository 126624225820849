import { useHeaderHeight } from "@react-navigation/elements";
import React from "react";
import {
	Platform,
	ScrollView,
	StyleSheet,
	TextInput,
	View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import DrawerBorder from "../components/DrawerBorder";
import FilterList from "../components/FilterList";
import { useMedication } from "../components/MedicationContext";
import NativeFilterResultsButton from "../components/NativeFilterResultsButton";
import { filtering } from "../store";

export default function FiltersScreen() {
	const textInputRef = React.useRef(null);

	const { loading, error } = useMedication();
	const state = useSelector(filtering.selectors.getState);

	React.useEffect(() => {
		if (state.mode === "search") {
			textInputRef.current.focus();
		}
	}, [state.mode]);

	const dispatch = useDispatch();
	const headerHeight = useHeaderHeight();
	React.useEffect(() => {
		dispatch(filtering.actions.setSheetHeaderHeight(headerHeight));
	}, [dispatch, headerHeight]);

	const onChangeText = React.useCallback(
		(text) => dispatch(filtering.actions.setSearchQuery(text)),
		[dispatch],
	);

	return (
		<View style={styles.container}>
			<ScrollView
				alwaysBounceVertical={false}
				indicatorStyle="white"
				style={styles.scrollView}
			>
				{state.mode === "filters" && !loading && !error && (
					<>
						<FilterList />
						{Platform.OS === "web" && <DrawerBorder />}
					</>
				)}
				{state.mode === "search" && (
					<>
						<TextInput
							ref={textInputRef}
							autoCapitalize="none"
							autoCompleteType="off"
							autoCorrect={false}
							clearButtonMode="always"
							disableFullscreenUI
							enablesReturnKeyAutomatically
							onChangeText={onChangeText}
							//onSubmitEditing={} // @todo: hide sheet?
							placeholder="Zoekterm"
							placeholderTextColor={
								Platform.OS === "web"
									? "rgba(0, 0, 0, 0.5)"
									: "rgba(255, 255, 255, 0.5)"
							}
							returnKeyType="search"
							textContentType="none"
							style={styles.textInput}
							value={state.searchQuery}
						/>
					</>
				)}
			</ScrollView>
			<NativeFilterResultsButton />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Platform.OS === "web" ? "#fff" : "#003399",
	},

	scrollView: {
		flex: 1,
		/*...Platform.select({
			ios: {
				borderTopWidth: StyleSheet.hairlineWidth,
				borderTopColor: "rgba(255, 255, 255, 0.2)",
			},
			default: {
				marginTop: Constants.statusBarHeight,
				paddingTop: 7.5,
			},
		}),*/
	},

	textInput: {
		margin: 15,
		padding: 10,
		borderRadius: 5,
		fontFamily: "Raleway_500Medium",
		fontSize: 20,
		color: Platform.OS === "web" ? "#000000" : "#FFFFFF",
		backgroundColor:
			Platform.OS === "web" ? "#FFFFFF" : "rgba(255, 255, 255, 0.1)",
		borderWidth: 1,
		borderColor:
			Platform.OS === "web"
				? "rgba(0, 0, 0, 0.08)"
				: "rgba(255, 255, 255, 0.15)",
	},
});
