import React from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import Touchable from "react-native-platform-touchable";

import TouchableWrapper from "./TouchableWrapper";

export default function NewsCard(props) {
	const { news: item } = props;

	return (
		<View style={[styles.wrapper, props.style]}>
			<TouchableWrapper borderRadius={10}>
				<Touchable onPress={props.onPress} style={styles.touchable}>
					<View style={[styles.card, !item.read && styles.unreadCard]}>
						{item.showImage && (
							<Image
								source={{ uri: item.imageFile.url }}
								style={styles.image}
							/>
						)}
						<View style={styles.textView}>
							{!item.read && <View style={styles.unreadDot} />}
							<Text
								style={[styles.dateText, !item.read && styles.unreadDateText]}
							>
								{item.date}
							</Text>
							<Text
								style={[styles.titleText, !item.read && styles.unreadTitleText]}
							>
								{item.title}
							</Text>
							<Text style={styles.text} numberOfLines={3}>
								{item.text.replaceAll(/[\s\n\r]+/g, " ")}
							</Text>
						</View>
					</View>
				</Touchable>
			</TouchableWrapper>
		</View>
	);
}

const styles = StyleSheet.create({
	wrapper: {
		borderRadius: 10,
		...Platform.select({
			android: {
				elevation: 4,
			},
			default: {
				shadowColor: "#000000",
				shadowOffset: { width: 0, height: 3 },
				shadowOpacity: 0.1,
				shadowRadius: 6,
			},
		}),
	},

	touchable: {
		flex: 1,
	},

	card: {
		flex: 1,
		flexDirection: "row",
		paddingVertical: 7,
		paddingHorizontal: 10,
		borderRadius: 10,
		borderWidth: StyleSheet.hairlineWidth,
		borderColor: "rgba(0, 0, 0, 0.08)",
		backgroundColor: "#FFFFFF",
	},

	unreadCard: {
		backgroundColor: "#F5FBFF",
	},

	image: {
		height: "100%",
		width: 100,
		resizeMode: "contain",
		marginRight: 10,
	},

	textView: {
		flex: 1,
	},

	unreadDot: {
		position: "absolute",
		zIndex: 10,
		right: 10,
		top: 10,
		width: 10,
		height: 10,
		borderRadius: 5,
		backgroundColor: "red",
	},

	dateText: {
		marginRight: 15,
		fontFamily: "Raleway_600SemiBold",
		fontSize: 15,
		color: "#8E8E92",
		textTransform: "uppercase",
	},

	unreadDateText: {
		color: "#0099CC",
	},

	titleText: {
		marginTop: 3,
		fontFamily: "Raleway_700Bold",
		fontSize: 18,
	},

	unreadTitleText: {
		color: "#003399",
	},

	text: {
		fontFamily: "Raleway_500Medium",
		fontSize: 15,
	},
});
