import React from "react";
import { AppState } from "react-native";
import { useSelector } from "react-redux";

import useDatabaseQuery from "../hooks/useDatabaseQuery";
import { news } from "../store";

export const NewsContext = React.createContext();

export function NewsProvider(props) {
	const newsRead = useSelector(news.selectors.getState).read;

	const value = useDatabaseQuery({
		query: "page('news').children.listed.sortBy('date', 'desc')",
		select: {
			uuid: true,
			title: true,
			date: "page.date.toDate('d MMMM yyyy')",
			text: "page.markdownText",
			showImage: true,
			imageFile: {
				query: "page.imageFile.toFile",
				select: ["width", "height", "url"],
			},
			showAttachment: true,
			attachmentFile: { query: "page.attachmentFile.toFile", select: ["url"] },
			attachmentTitle: true,
			showLink: true,
			linkUrl: true,
			linkTitle: true,
		},
	});

	const processedValue = React.useMemo(
		() => ({
			...value,
			result: (value.result || []).map((item) => ({
				...item,
				showImage: item.showImage === "true",
				showAttachment: item.showAttachment === "true",
				showLink: item.showLink === "true",
				read: newsRead.includes(item.uuid),
			})),
		}),
		[newsRead, value],
	);

	React.useEffect(() => {
		const subscription = AppState.addEventListener(
			"change",
			(appState) => appState === "active" && value.reload(),
		);

		return () => subscription.remove();
	}, [value.reload]);

	return (
		<NewsContext.Provider value={processedValue}>
			{props.children}
		</NewsContext.Provider>
	);
}

export function useNews() {
	return React.useContext(NewsContext);
}
