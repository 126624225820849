import React from "react";
import { useSelector } from "react-redux";

import { useFilteredMedicines } from "../components/MedicationContext";
import MedicinesList from "../components/MedicinesList";
import { filtering } from "../store";

export default function FilteredMedicinesScreen({ scrollViewRef }) {
	const filters = useSelector(filtering.selectors.getState).filters;
	const medicines = useFilteredMedicines(filters);

	return (
		<MedicinesList
			scrollViewRef={scrollViewRef}
			medicines={medicines}
			emptyText="Geen resultaten gevonden.\nPas uw filters aan."
		/>
	);
}
