import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { Alert, StyleSheet, TouchableOpacity, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { isFavorited } from "./MedicationContext";
import { favorites } from "../store";

export default function FavoriteButton({ medicine, style }) {
	const favoriteIds = useSelector(favorites.selectors.getState);
	const active = isFavorited(medicine, favoriteIds);

	const dispatch = useDispatch();
	const toggle = React.useCallback(
		() =>
			dispatch(
				favorites.actions.toggle({
					uuid: medicine.uuid,
					oldIds: medicine.oldIds,
				}),
			),
		[dispatch, medicine],
	);

	const onPress = React.useCallback(() => {
		if (active) {
			const name = (medicine.brand ? medicine.brand + " " : "") + medicine.name;

			if (Platform.OS === "web") {
				window.confirm(`“${name}” uit favorieten verwijderen?`) && toggle();
			} else {
				Alert.alert(`“${name}” uit favorieten verwijderen?`, null, [
					{ text: "Annuleren", style: "cancel" },
					{ text: "Verwijderen", style: "destructive", onPress: toggle },
				]);
			}
		} else {
			toggle();
		}
	}, [active, medicine, toggle]);

	return (
		<TouchableOpacity
			onPress={onPress}
			style={[styles.container, style]}
			hitSlop={{ top: 15, left: 15, bottom: 15, right: 15 }}
		>
			<FontAwesome5
				name="star"
				solid={active}
				style={[styles.icon, active && styles.iconActive]}
			/>
		</TouchableOpacity>
	);
}

const styles = StyleSheet.create({
	container: {},

	icon: {
		height: 22,
		fontSize: 22,
		lineHeight: 22,
		color: "rgba(0, 0, 0, 0.2)",
	},

	iconActive: {
		color: "#FED766",
	},
});
