import React from "react";
import { useSelector } from "react-redux";

import { useSearchedMedicines } from "../components/MedicationContext";
import MedicinesList from "../components/MedicinesList";
import { filtering } from "../store";

export default function SearchedMedicinesScreen({ scrollViewRef }) {
	const searchQuery = useSelector(filtering.selectors.getState).searchQuery;
	const medicines = useSearchedMedicines(searchQuery);

	return (
		<MedicinesList
			scrollViewRef={scrollViewRef}
			medicines={searchQuery ? medicines : []}
			emptyText={
				searchQuery
					? `Geen resultaten gevonden voor “${searchQuery}”.\nPas uw zoekterm aan.`
					: "Voer een zoekterm in."
			}
		/>
	);
}
