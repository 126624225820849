import React from "react";
import { StyleSheet, Text } from "react-native";
import Touchable from "react-native-platform-touchable";

import TouchableWrapper from "./TouchableWrapper";

export default function SecondarySetupButton(props) {
	return (
		<TouchableWrapper borderRadius={2} style={styles.androidWrapperStyle}>
			<Touchable {...props} style={[styles.button, props.style]}>
				<Text style={styles.text}>{props.title}</Text>
			</Touchable>
		</TouchableWrapper>
	);
}

const styles = StyleSheet.create({
	androidWrapperStyle: {
		alignSelf: "center",
		marginTop: 5,
	},

	button: {
		paddingVertical: 10,
		paddingHorizontal: 15,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 2,
	},

	text: {
		color: "#AAAAAA",
		fontFamily: "Raleway_700Bold",
		fontSize: 15,
		textAlign: "center",
	},
});
