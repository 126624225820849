import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import Touchable from "react-native-platform-touchable";

export default function DrawerItem(props) {
	let iconStyle = StyleSheet.flatten([
		styles.icon,
		props.dark && styles.iconDark,
		props.iconStyle,
	]);
	iconStyle = {
		...iconStyle,
		marginTop: iconStyle.marginTop - ICON_PADDING,
		marginRight: iconStyle.marginRight - ICON_PADDING,
		marginBottom: -ICON_PADDING,
		marginLeft: -ICON_PADDING,
		width: iconStyle.fontSize + 2 * ICON_PADDING,
		height: iconStyle.fontSize + 2 * ICON_PADDING,
		lineHeight: iconStyle.fontSize + 2 * ICON_PADDING,
	};

	return (
		<Touchable onPress={props.onPress}>
			<View style={[styles.container, props.style]}>
				{props.icon && (
					<FontAwesome5
						name={props.icon}
						solid={props.iconSolid}
						style={iconStyle}
					/>
				)}
				<View style={styles.texts}>
					<Text
						style={[
							styles.title,
							props.dark && styles.titleDark,
							props.titleStyle,
						]}
					>
						{props.title}
					</Text>
					<Text style={[styles.value, props.dark && styles.valueDark]}>
						{props.value}
					</Text>
				</View>
				{props.chevron && (
					<FontAwesome5
						name="chevron-right"
						style={[styles.chevron, props.dark && styles.chevronDark]}
					/>
				)}
			</View>
		</Touchable>
	);
}

const ICON_PADDING = 2;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		paddingVertical: Platform.OS === "web" ? 22.5 : 12,
		paddingHorizontal: 15,
	},

	icon: {
		marginTop: 1,
		marginRight: 10,
		fontSize: 15,
		textAlign: "center",
		color: "rgba(0, 0, 0, 0.2)",
	},

	iconDark: {
		color: "rgba(255, 255, 255, 0.4)",
	},

	texts: {
		flex: 1,
		flexDirection: "row",
	},

	title: {
		fontFamily: "Raleway_500Medium",
		fontSize: 15,
		marginRight: 10,
	},

	titleDark: {
		color: "#FFFFFF",
	},

	value: {
		flex: 1,
		fontFamily: "Raleway_500Medium",
		fontSize: 15,
		textAlign: "right",
	},

	valueDark: {
		color: "#FFFFFF",
	},

	chevron: {
		marginTop: 1,
		marginLeft: 10,
		height: 15,
		fontSize: 15,
		color: "rgba(0, 0, 0, 0.2)",
	},

	chevronDark: {
		color: "rgba(255, 255, 255, 0.4)",
	},
});
