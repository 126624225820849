import React from "react";
import { useSelector } from "react-redux";

import SetupNavigator from "./SetupNavigator";
import TabNavigator from "./TabNavigator";
import { VERSION } from "../screens/WelcomeScreen";
import { session } from "../store";

export default function RootNavigator() {
	const isSetUp =
		useSelector(session.selectors.getState).setupVersionCompleted >= VERSION;

	if (!isSetUp) {
		return <SetupNavigator />;
	}

	return <TabNavigator />;
}
