import { useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView, StyleSheet } from "react-native";

import DrawerBorder from "../components/DrawerBorder";
import DrawerItem from "../components/DrawerItem";

export default function MoreScreen() {
	const navigation = useNavigation();

	return (
		<ScrollView
			contentInsetAdjustmentBehavior="automatic"
			style={styles.scrollView}
			contentContainerStyle={styles.content}
		>
			<DrawerItem
				icon="file-medical"
				title="SMPC"
				onPress={() => navigation.navigate("Smpc.Index")}
				chevron
			/>
			<DrawerBorder />
			<DrawerItem
				icon="copyright"
				title="Disclaimer & privacy"
				onPress={() => navigation.navigate("Disclaimer")}
				chevron
			/>
			<DrawerBorder />
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		flex: 1,
	},

	content: {
		width: "100%",
		maxWidth: 400,
		alignSelf: "center",
		paddingVertical: 20,
	},
});
