import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Platform } from "react-native";

import { DarkHeader, GrayHeader } from "./Options";
import NewsItemScreen from "../screens/NewsItemScreen";
import NewsScreen from "../screens/NewsScreen";

const Stack = createStackNavigator();

export default function NewsNavigator() {
	return (
		<Stack.Navigator
			screenOptions={{
				...(Platform.OS === "web" ? GrayHeader : DarkHeader),
				cardStyle: { flex: 1, backgroundColor: "#FFFFFF" },
			}}
		>
			<Stack.Screen
				name="News.Index"
				component={NewsScreen}
				options={{
					title: "Nieuws",
					headerShown: Platform.OS !== "web",
				}}
			/>
			<Stack.Screen
				name="News.Item"
				component={NewsItemScreen}
				options={({ route }) => ({ title: route.params.news.title })}
			/>
		</Stack.Navigator>
	);
}
