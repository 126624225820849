import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import FilteredMedicinesScreen from "./FilteredMedicinesScreen";
import SearchedMedicinesScreen from "./SearchedMedicinesScreen";
import FilterBar from "../components/FilterBar";
import {
	MedicationContext,
	useMedication,
} from "../components/MedicationContext";
import FilterNavigationContainer from "../navigation/FilterNavigationContainer";
import FilterNavigator from "../navigation/FilterNavigator";
import { filtering } from "../store";

export default function MedicinesScreen() {
	const scrollView = React.createRef();

	const medication = useMedication();

	const state = useSelector(filtering.selectors.getState);

	// Scroll to top if filters changed
	/*React.useEffect(() => {
		scrollView.current && scrollView.current.scrollTo({ y: 0 });
	}, state);*/

	/*const keyboardHeight = useKeyboardHeight();

	const sheetHandleHeight = 24;
	const sheetHeaderHeight = useSelector(
		filtering.selectors.getSheetHeaderHeight,
	);

	const searchHeight =
		sheetHandleHeight + sheetHeaderHeight + keyboardHeight + 42 + 2 * 15;*/

	return (
		<View style={{ flex: 1 }}>
			<FilterBar showSheet={() => null} sheetVisible />
			<View style={{ flex: 1, flexDirection: "row", alignItems: "stretch" }}>
				{state.mode !== "brand" && (
					<View
						style={{
							width: 400,
							borderRightWidth: 1,
							borderColor: "rgb(216, 216, 216)",
						}}
					>
						<FilterNavigationContainer>
							<MedicationContext.Provider value={medication}>
								<FilterNavigator />
							</MedicationContext.Provider>
						</FilterNavigationContainer>
					</View>
				)}
				<View style={{ flex: 1 /*, backgroundColor: "red"*/ }}>
					{state.mode === "search" ? (
						<SearchedMedicinesScreen scrollViewRef={scrollView} />
					) : (
						<FilteredMedicinesScreen scrollViewRef={scrollView} />
					)}
				</View>
			</View>
		</View>
	);
}
