import React from "react";
import { StyleSheet, Text } from "react-native";
import Touchable from "react-native-platform-touchable";

import TouchableWrapper from "./TouchableWrapper";

export default function SetupButton(props) {
	return (
		<TouchableWrapper borderRadius={8} style={{ elevation: 2 }}>
			<Touchable
				{...props}
				style={[styles.button, props.disabled && styles.disabled, props.style]}
			>
				<Text style={styles.text}>{props.title}</Text>
			</Touchable>
		</TouchableWrapper>
	);
}

const styles = StyleSheet.create({
	button: {
		height: 50,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#003399",
	},

	disabled: {
		backgroundColor: "#CCCCCC",
	},

	text: {
		color: "#FFFFFF",
		fontFamily: "Raleway_700Bold",
		fontSize: 20,
		textAlign: "center",
	},
});
