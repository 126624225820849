import { StyleSheet, Text, useWindowDimensions } from "react-native";

export default function TabBadge({ tabCount, children }) {
	const windowWidth = useWindowDimensions().width;
	const tabWidth = windowWidth / tabCount;
	const iconWidth = 24;

	const position = {
		top: 5,
		right: tabWidth / 2 - iconWidth,
	};

	return <Text style={[styles.text, position]}>{children}</Text>;
}

const styles = StyleSheet.create({
	text: {
		backgroundColor: "red",
		color: "white",
		fontSize: 15,
		lineHeight: 20,
		height: 20,
		minWidth: 20,
		paddingHorizontal: 4,
		borderRadius: 10,
		textAlign: "center",
		position: "absolute",
	},
});
