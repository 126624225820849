import React from "react";
import { StyleSheet, View } from "react-native";

export default function DrawerBorder(props) {
	return <View style={[styles.container, props.dark && styles.dark]} />;
}

const styles = StyleSheet.create({
	container: {
		height: StyleSheet.hairlineWidth,
		marginHorizontal: 15,
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},

	dark: {
		backgroundColor: "rgba(255, 255, 255, 0.15)",
	},
});
