import React from "react";
import { StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const RootSafeAreaInsetsContext = React.createContext({
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
});

export function RootSafeAreaProvider({ children }) {
	const insets = useSafeAreaInsets();

	return (
		<RootSafeAreaInsetsContext.Provider value={insets}>
			{children}
		</RootSafeAreaInsetsContext.Provider>
	);
}

export function useRootSafeAreaInsets() {
	return React.useContext(RootSafeAreaInsetsContext);
}

const styleName = (prefix, suffix) =>
	prefix + (suffix ? suffix.charAt(0).toUpperCase() + suffix.slice(1) : suffix);

const styleValue = (style, mode, edge) => {
	const suffixes = [
		edge,
		...(edge in ["top", "bottom"] ? ["vertical"] : []),
		...(edge in ["left", "right"] ? ["horizontal"] : []),
		"",
	];

	for (const suffix of suffixes) {
		const key = styleName(mode, suffix);
		if (style[key] !== undefined) {
			return style[key];
		}
	}
};

const insetStyle = (style, insets, edges, mode) => {
	for (const edge of edges) {
		if (insets[edge]) {
			const key = styleName(mode, edge);
			const baseValue = styleValue(style, mode, edge);
			style[key] = insets[edge] + (baseValue || 0);
		}
	}

	return style;
};

export function RootSafeAreaView({ edges, mode, style, ...props }) {
	style = insetStyle(
		{ ...StyleSheet.flatten(style) },
		useRootSafeAreaInsets(),
		edges || ["top", "right", "bottom", "left"],
		mode || "padding",
	);

	return <View style={style} {...props} />;
}
