import { NavigationContainer } from "@react-navigation/native";

export default function RootNavigationContainer({ children }) {
	return (
		<NavigationContainer
			documentTitle={{ formatter: () => "Santen Glaucoommedicatie" }}
		>
			{children}
		</NavigationContainer>
	);
}
