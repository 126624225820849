const Header = {
	/*headerTitleContainerStyle: Platform.select({
		ios: { left: 48, right: 48 },
	}),*/
};

export const GrayHeader = {
	...Header,
	headerStyle: {
		backgroundColor: "#f0f0f0",
	},
	headerTintColor: "#003399",
};

export const LightHeader = {
	...Header,
	headerStyle: {
		backgroundColor: "#fff",
	},
	headerTintColor: "#003399",
};

export const DarkHeader = {
	...Header,
	headerStyle: {
		backgroundColor: "#003399",
		borderBottomColor: "rgba(255, 255, 255, 0.2)",
	},
	headerTintColor: "#FFFFFF",
};
