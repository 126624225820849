import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useSelector } from "react-redux";

import { DarkHeader } from "./Options";
import HeaderButtons, { Item } from "../components/HeaderButtons";
import DisclaimerScreen from "../screens/DisclaimerScreen";
import LoginScreen from "../screens/LoginScreen";
import SplashScreen from "../screens/SplashScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import { session } from "../store";

const MainStack = createStackNavigator();
const RootStack = createStackNavigator();

function MainNavigator() {
	const isLoggedIn = !!useSelector(session.selectors.getState).loginCode;

	return (
		<MainStack.Navigator
			initialRouteName={isLoggedIn ? "Setup_Welcome" : "Setup_Splash"}
			screenOptions={{
				cardStyle: { flex: 1, backgroundColor: "#FFFFFF" },
				headerShown: false,
				gestureEnabled: false,
			}}
		>
			<MainStack.Screen name="Setup_Splash" component={SplashScreen} />
			<MainStack.Screen name="Setup_Login" component={LoginScreen} />
			<MainStack.Screen name="Setup_Welcome" component={WelcomeScreen} />
		</MainStack.Navigator>
	);
}

export default function RootNavigator() {
	return (
		<RootStack.Navigator
			screenOptions={{
				...DarkHeader,
				cardStyle: { flex: 1, backgroundColor: "#FFFFFF" },
			}}
		>
			<RootStack.Screen
				name="Setup"
				component={MainNavigator}
				options={{ headerShown: false }}
			/>
			<RootStack.Screen
				name="Setup_Disclaimer"
				component={DisclaimerScreen}
				options={{
					presentation: "modal",
					title: "Disclaimer & privacy",
					headerLeft: ({ onPress }) => (
						<HeaderButtons left>
							<Item title="Sluit" iconName="times" onPress={onPress} />
						</HeaderButtons>
					),
				}}
			/>
		</RootStack.Navigator>
	);
}
