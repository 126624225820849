import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Touchable from "react-native-platform-touchable";

import TouchableWrapper from "./TouchableWrapper";

export default function FilterModeButton(props) {
	return (
		<TouchableWrapper borderRadius={5} style={{ elevation: 2 }}>
			<Touchable
				{...props}
				style={[styles.button, props.selected && styles.selected, props.style]}
			>
				<View style={styles.container}>
					{props.icon && (
						<FontAwesome5
							name={props.icon}
							style={[styles.icon, props.selected && styles.selectedContent]}
						/>
					)}
					<Text style={[styles.text, props.selected && styles.selectedContent]}>
						{props.title}
					</Text>
				</View>
			</Touchable>
		</TouchableWrapper>
	);
}

const styles = StyleSheet.create({
	button: {
		height: 43,
		borderRadius: 5,
		backgroundColor: "#FFFFFF",
		borderColor: "rgb(216, 216, 216)",
		borderWidth: 1,
	},

	selected: {
		backgroundColor: "#003399",
		borderColor: "#003399",
	},

	container: {
		flex: 1,
		flexDirection: "row",
		paddingHorizontal: 15,
		alignItems: "center",
	},

	icon: {
		marginRight: 10,
		marginTop: 1,
		height: 15,
		fontSize: 15,
		color: "#0099CC",
	},

	text: {
		color: "#8E8E92",
		fontFamily: "Raleway_600SemiBold",
		fontSize: 16,
	},

	selectedContent: {
		color: "#FFFFFF",
	},
});
