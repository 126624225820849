import React from "react";
import {
	Alert,
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";

import LoginForm from "../components/LoginForm";
import SetupButton from "../components/SetupButton";
import LoginCodes from "../constants/LoginCodes";
import { session } from "../store";

export default function LoginScreen(props) {
	const dispatch = useDispatch();
	const [login, setLogin] = React.useState({ code: null });
	const formatLogin = (code) => code.trim().toUpperCase();

	const onChange = ({ code }) =>
		setLogin({
			code: Platform.select({
				android: code,
				default: formatLogin(code),
			}),
		});

	const next = () => {
		const loginCode = formatLogin(login.code);
		if (LoginCodes.includes(loginCode)) {
			dispatch(session.actions.setLoginCode({ loginCode }));
			props.navigation.navigate("Setup_Welcome");
		} else {
			if (Platform.OS === "web") {
				window.alert("Ongeldige inlogcode");
			} else {
				Alert.alert("Ongeldige inlogcode", null, [
					{ text: "OK", style: "cancel" },
				]);
			}
		}
	};

	return (
		<KeyboardAvoidingView
			style={styles.container}
			behavior={Platform.OS === "android" ? "margin" : "padding"}
		>
			<SafeAreaView style={styles.content}>
				<ScrollView
					alwaysBounceVertical={false}
					keyboardShouldPersistTaps="handled"
				>
					<Text style={styles.title}>Inloggen</Text>
					<Text style={styles.text}>
						Deze app is bedoeld voor zorgprofessionals. Om deze te gebruiken,
						heeft u een inlogcode nodig. Voer de inlogcode in die u van uw
						contactpersoon heeft gekregen.
					</Text>
					<LoginForm login={login} onChange={onChange} onSubmit={next} />
				</ScrollView>
			</SafeAreaView>
			<SafeAreaView style={styles.buttonContainer}>
				<SetupButton
					title="Volgende"
					disabled={!LoginForm.validate(login)}
					onPress={next}
				/>
			</SafeAreaView>
		</KeyboardAvoidingView>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},

	content: {
		flex: 1,
	},

	title: {
		...(Platform.OS === "android" && { marginTop: 20 }),
		padding: 20,
		paddingBottom: 0,
		fontFamily: "Raleway_700Bold",
		fontSize: 25,
	},

	text: {
		fontFamily: "Raleway_500Medium",
		fontSize: 20,
		padding: 20,
		paddingBottom: 0,
	},

	buttonContainer: {
		marginHorizontal: 20,
		marginVertical: 10,
	},
});
