import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
	Linking,
	Platform,
	RefreshControl,
	ScrollView,
	StyleSheet,
} from "react-native";

import DrawerBorder from "../components/DrawerBorder";
import DrawerItem from "../components/DrawerItem";
import LoadingState from "../components/LoadingState";
import { useSmpc } from "../components/SmpcContext";
import useRefreshControlVisibleWhileLoading from "../hooks/useRefreshControlVisibleWhileLoading";

export default function SmpcScreen() {
	const navigation = useNavigation();
	const { loading, error, result, reload } = useSmpc();
	const refreshControlVisible = useRefreshControlVisibleWhileLoading(loading);
	const empty = result && !result.length;

	const onPress = (smpc) =>
		Platform.select({
			web: () => Linking.openURL(smpc.url),
			default: () => navigation.navigate("Smpc.Item", { smpc }),
		});

	return (
		<ScrollView
			contentInsetAdjustmentBehavior="automatic"
			style={styles.scrollView}
			contentContainerStyle={styles.content}
			refreshControl={
				refreshControlVisible && (
					<RefreshControl refreshing={loading} enabled={false} />
				)
			}
		>
			<LoadingState error={error} empty={empty} onReload={reload}>
				{result &&
					result.map((smpc, i) => (
						<React.Fragment key={i}>
							<DrawerItem title={smpc.title} onPress={onPress(smpc)} chevron />
							<DrawerBorder />
						</React.Fragment>
					))}
			</LoadingState>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		flex: 1,
	},

	content: {
		width: "100%",
		maxWidth: 400,
		alignSelf: "center",
		paddingVertical: 20,
	},
});
