import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";

import FilterModeButtons from "./FilterModeButtons";
import { GrayHeader } from "../navigation/Options";

export default function FilterBar({ showSheet, sheetVisible }) {
	return (
		<View style={styles.headerBar}>
			<ScrollView
				horizontal
				alwaysBounceHorizontal={false}
				automaticallyAdjustContentInsets={false}
				centerContent
				contentContainerStyle={styles.headerBarScrollContent}
				style={styles.headerBarScroll}
			>
				<FilterModeButtons showSheet={showSheet} sheetVisible={sheetVisible} />
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	headerBar: {
		borderBottomWidth: 1,
		borderColor: "rgb(216, 216, 216)",
		backgroundColor: GrayHeader.headerStyle.backgroundColor,
	},

	headerBarScroll: {},

	headerBarScrollContent: {
		paddingVertical: 10,
		paddingHorizontal: 15,
	},
});
