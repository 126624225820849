import React from "react";
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";

import SetupButton from "../components/SetupButton";
import { session } from "../store";

export const VERSION = 1;

export default function WelcomeScreen() {
	const dispatch = useDispatch();

	const done = React.useCallback(
		() =>
			dispatch(
				session.actions.setSetupVersionCompleted({
					setupVersionCompleted: VERSION,
				}),
			),
		[dispatch],
	);

	return (
		<View style={styles.container}>
			<SafeAreaView style={styles.content}>
				<ScrollView alwaysBounceVertical={false}>
					<View style={styles.section}>
						<Text style={styles.title}>Welkom!</Text>
						<Text style={styles.text}>
							Deze app biedt een overzicht van glaucoommedicatie beschikbaar in
							Nederland.
						</Text>
						<Text style={styles.text}>
							Middels de filters kunt u zoeken naar medicatie met bepaalde
							eigenschappen. U kunt meerdere filters combineren. De app toont
							vervolgens alle medicatie die aan de gekozen filters voldoet.
						</Text>
						<Text style={styles.text}>
							Zo kunt u, dankzij deze app, gemakkelijk de meest geschikte
							glaucoommedicatie vinden!
						</Text>
					</View>
				</ScrollView>
			</SafeAreaView>
			<SafeAreaView style={styles.buttonContainer}>
				<SetupButton title="Aan de slag" onPress={done} />
			</SafeAreaView>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},

	content: {
		flex: 1,
	},

	section: {
		...(Platform.OS === "android" && { marginTop: 20 }),
		padding: 20,
	},

	title: {
		fontFamily: "Raleway_700Bold",
		fontSize: 25,
	},

	text: {
		fontFamily: "Raleway_500Medium",
		fontSize: 20,
		marginTop: 20,
	},

	buttonContainer: {
		marginHorizontal: 20,
		marginVertical: 10,
	},
});
