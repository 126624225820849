import React from "react";
import {
	Image,
	Linking,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from "react-native";

import Disclaimer from "../constants/Disclaimer";

export default function DisclaimerScreen() {
	return (
		<ScrollView
			contentInsetAdjustmentBehavior="automatic"
			style={styles.scrollView}
			contentContainerStyle={styles.content}
		>
			{Disclaimer.map((section, i) => (
				<View key={i} style={styles.section}>
					<Text style={styles.title}>{section.title}</Text>
					{section.phrases &&
						section.phrases.map((phrase, j) => (
							<Text key={`${i}.${j}`} style={styles.text}>
								{phrase}
							</Text>
						))}
					{section.logos &&
						section.logos.map((logo, j) => (
							<Image
								key={`${i}.${j}`}
								source={logo.source}
								resizeMethod="scale"
								style={styles.logo}
							/>
						))}
					{section.sources && (
						<Text style={styles.text}>
							{Object.entries(section.sources).map(([source, url], j) => (
								<React.Fragment key={`${i}.${j}`}>
									{j > 0 && ", "}
									<Text
										style={styles.link}
										onPress={() => Linking.openURL(url)}
									>
										{source}
									</Text>
								</React.Fragment>
							))}
						</Text>
					)}
				</View>
			))}
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		flex: 1,
	},

	content: {
		width: "100%",
		maxWidth: 800,
		alignSelf: "center",
	},

	section: {
		padding: 20,
	},

	title: {
		fontFamily: "Raleway_700Bold",
		fontSize: 25,
	},

	text: {
		fontFamily: "Raleway_500Medium",
		fontSize: 20,
		marginTop: 5,
	},

	link: {
		color: "#003399",
		textDecorationLine: "underline",
	},

	logo: {
		marginTop: 30,
		width: null,
		height: 45,
		resizeMode: "contain",
	},
});
