import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import DrawerBorder from "./DrawerBorder";
import DrawerItem from "./DrawerItem";
import { useMedication } from "./MedicationContext";
import { filtering } from "../store";

export default function FilterList() {
	const navigation = useNavigation();
	const properties = useMedication().result.properties;
	const filters = properties.filter((property) => property.filterable);
	const activeFilters = useSelector(filtering.selectors.getState).filters;

	const isActiveFilter = (filter) => (activeFilters[filter.key] || []).length;

	const activeFilterValues = (filter) =>
		filter.filterValues
			.filter((value) =>
				(activeFilters[filter.key] || []).includes(value.filterText || value),
			)
			.map((value) => value.label || value)
			.join(", ");

	return filters.map((filter, i) => (
		<React.Fragment key={filter.key}>
			{i > 0 && <DrawerBorder dark={Platform.OS !== "web"} />}
			<DrawerItem
				title={filter.label}
				value={activeFilterValues(filter)}
				dark={Platform.OS !== "web"}
				chevron
				titleStyle={
					isActiveFilter(filter)
						? {
								fontFamily: "Raleway_700Bold",
								color: Platform.OS === "web" ? "#000000" : "#FFFFFF",
						  }
						: {
								color:
									Platform.OS === "web"
										? "#8E8E92"
										: "rgba(255, 255, 255, 0.8)",
						  }
				}
				onPress={() => navigation.navigate("Filter", { filter })}
			/>
		</React.Fragment>
	));
}
