import React from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import DrawerBorder from "../components/DrawerBorder";
import DrawerItem from "../components/DrawerItem";
import NativeFilterResultsButton from "../components/NativeFilterResultsButton";
import { filtering } from "../store";

export default function FilterScreen(props) {
	const filter = props.route.params.filter;

	const activeFilters = useSelector(filtering.selectors.getState).filters;
	const selectedValues = activeFilters[filter.key] || [];

	const dispatch = useDispatch();
	const toggle = (value) =>
		dispatch(filtering.actions.toggleFilter({ property: filter.key, value }));

	return (
		<View style={styles.container}>
			<ScrollView
				alwaysBounceVertical={false}
				indicatorStyle="white"
				style={styles.scrollView}
			>
				{filter.filterValues.map((value, i) => {
					const selected = selectedValues.includes(value.filterText || value);
					return (
						<React.Fragment key={i}>
							{i > 0 && <DrawerBorder dark={Platform.OS !== "web"} />}
							<DrawerItem
								title={value.label || value}
								dark={Platform.OS !== "web"}
								icon={selected ? "check-square" : "square"}
								iconSolid={selected}
								iconStyle={
									selected && {
										color: Platform.OS === "web" ? "#0099CC" : "#FFFFFF",
									}
								}
								onPress={() => toggle(value.filterText || value)}
							/>
						</React.Fragment>
					);
				})}
				{Platform.OS === "web" && <DrawerBorder />}
			</ScrollView>
			<NativeFilterResultsButton />
		</View>
	);
}

FilterScreen.navigationOptions = ({ navigation }) => ({
	title: navigation.getParam("filter").label || "Filter",
});

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Platform.OS === "web" ? "#fff" : "#003399",
	},

	scrollView: {
		flex: 1,
	},
});
