import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Platform } from "react-native";

import { DarkHeader, GrayHeader } from "./Options";
import DisclaimerScreen from "../screens/DisclaimerScreen";
import MoreScreen from "../screens/MoreScreen";
import SmpcItemScreen from "../screens/SmpcItemScreen";
import SmpcScreen from "../screens/SmpcScreen";

const Stack = createStackNavigator();

export default function MoreNavigator() {
	return (
		<Stack.Navigator
			screenOptions={{
				...(Platform.OS === "web" ? GrayHeader : DarkHeader),
				cardStyle: { flex: 1, backgroundColor: "#FFFFFF" },
			}}
		>
			<Stack.Screen
				name="More.Index"
				component={MoreScreen}
				options={{
					title: "Meer",
					headerShown: Platform.OS !== "web",
				}}
			/>
			<Stack.Screen
				name="Disclaimer"
				component={DisclaimerScreen}
				options={{
					title: "Disclaimer & privacy",
				}}
			/>
			<Stack.Screen
				name="Smpc.Index"
				component={SmpcScreen}
				options={{
					title: "SMPC",
				}}
			/>
			<Stack.Screen
				name="Smpc.Item"
				component={SmpcItemScreen}
				options={({ route }) => ({ title: route.params.smpc.title })}
			/>
		</Stack.Navigator>
	);
}
