import React from "react";
import { Platform } from "react-native";
import WebView from "react-native-webview";

export default function SmpcItemScreen(props) {
	const webViewRef = React.useRef(null);

	const { smpc: item } = props.route.params;

	const url = Platform.select({
		android:
			"https://drive.google.com/viewerng/viewer?embedded=true&url=" +
			encodeURIComponent(item.url),
		default: item.url,
	});

	return (
		<WebView
			ref={webViewRef}
			source={{ uri: url }}
			/*onNavigationStateChange={(state) => {
				if (state.url !== url) {
					webViewRef.current.stopLoading();
					Linking.openURL(state.url);
				}
			}}*/
		/>
	);
}
